import React, { useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import Card from "../card/Card";
import StopType from "../stopType/StopType";
import { formatVolume } from "../../formatting/volume";
import { formatWeight } from "../../formatting/weight";
import ProductsList from "../productsList/ProductsList";

const ClientLogo = styled.div`
  margin-bottom: 15px;
  img {
    width: 166px;
  }
  @media screen and (max-width: 39.9375em) {
    img {
      width: 130px;
    }
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  p {
    position: relative;
    margin-left: 10px;
  }

  .type {
    margin-bottom: 10px;
  }
`;

const Wrapper = styled.div`
  cursor: pointer;
  z-index: 2;
  position: relative;
`;

const OtherStop = ({ clientLogo, clientName, packageDetails, otherStop }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const { t } = useTranslation();

  return (
    <>
      <Wrapper onClick={() => setModalOpen(true)}>
        <Card className="text-center">
          <ClientLogo>
            {clientLogo ? (
              <img src={clientLogo} alt={clientName} />
            ) : (
              <h2>{clientName}</h2>
            )}
          </ClientLogo>

          <p className="card-description">{t("colli_description")}</p>

          <Row>
            <div className="type">
              <StopType isPickup={otherStop.type === "pickup"} />
            </div>
            <p>{otherStop.name}</p>
          </Row>

          <div className="package-details">
            <div className="detail">
              <span className="icon packages"></span>
              {`${packageDetails?.totalNumber ?? 0} ${t("colli")}`}
            </div>

            <div className="detail">
              <span className="icon weight"></span>
              {formatWeight(packageDetails?.totalWeight)}
            </div>

            <div className="detail">
              <span className="icon volume"></span>
              {formatVolume(packageDetails?.totalVolume)}
            </div>
          </div>
          <div className="right-arrow-icon"></div>
        </Card>
      </Wrapper>

      <ProductsList
        products={packageDetails?.packages}
        isOpen={modalOpen}
        onOverlayClick={() => {
          setModalOpen(false);
        }}
      ></ProductsList>
    </>
  );
};

export default OtherStop;
