import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import pickup from "../../images/pickup.svg";
import deliver from "../../images/deliver.svg";

const Wrapper = styled.div`
  border-radius: 16px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  letter-spacing: -0.3px;
  color: ${(props) => props.theme.colors.deepSapphire};
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 22px;

  & > .type-text {
    margin-right: 10px;
    margin-left: 5px;
  }
`;

const Pickup = styled(Wrapper)`
  background-color: #bdd4ff;
  color: #032f85;
`;

const Delivery = styled(Wrapper)`
  background-color: #f2bdff;
  color: #5e00d6;
`;
const Icon = styled.div`
  width: 22px;
  height: 22px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 22px 22px;
`;

const PickupIcon = styled(Icon)`
  background-image: url("${pickup}");
`;

const DeliveryIcon = styled(Icon)`
  background-image: url("${deliver}");
`;

const StopType = ({ isPickup }) => {
  const { t } = useTranslation();
  return isPickup ? (
    <Pickup>
      <PickupIcon />
      <span className="type-text">{t("pick_up")}</span>
    </Pickup>
  ) : (
    <Delivery>
      <DeliveryIcon />
      <span className="type-text">{t("delivery")}</span>
    </Delivery>
  );
};

export default StopType;
