import React from "react";
import * as dayjs from "dayjs";
import Card from "../card/Card";
import PieIcon from "../pieIcon/PieIcon";
import styled, { css } from "styled-components";
import { useTranslation } from "react-i18next";
import StopType from "../stopType/StopType";
import { dateFormat } from "../CommonStyledComponents";

const TimeWrapper = styled.div`
  display: flex;
  align-items: center;
  ${(props) =>
    props.completed
      ? css`
          color: #00cea9;
        `
      : css`
          color: #0049d6;
        `}
`;

const Time = styled.span`
  margin-left: 5px;
  ${(props) =>
    props.isNext
      ? css`
          font-weight: bold;
        `
      : css`
          font-weight: standard;
        `};
`;

const Address = ({
  addressType,
  address,
  estimatedTime,
  completedAt,
  isNext,
  estimatesLive,
}) => {
  let buildAddress = () => {
    let completeAddress = "";

    let {
      address1,
      address2,
      address3,
      addressNumber,
      postalCode,
      addressRegion,
    } = address.address;

    if (address1 != null) {
      completeAddress += address1;
    }

    if (address2 != null) {
      completeAddress = completeAddress + " " + address2;
    }

    if (address3 != null) {
      completeAddress = completeAddress + " " + address3;
    }

    if (addressNumber != null) {
      completeAddress = completeAddress + " " + addressNumber;
    }

    if (postalCode != null) {
      completeAddress = completeAddress + " " + postalCode;
    }

    if (addressRegion != null) {
      completeAddress = completeAddress + ", " + addressRegion;
    }

    return completeAddress;
  };

  const { t } = useTranslation();

  return (
    <Card>
      <div className="type-time-wrapper">
        <StopType isPickup={addressType === "pickup"} />
        <TimeWrapper completed={!!completedAt}>
          {completedAt ? (
            <>{`${
              addressType === "pickup" ? t("picked_up") : t("delivered")
            }: ${dayjs(completedAt).format(dateFormat)}`}</>
          ) : (
            <>
              {estimatesLive && <PieIcon />}
              <>
                {t("estimated") + ` ${addressType}: `}
                <Time isNext={isNext}>
                  {dayjs(estimatedTime).format(dateFormat)}
                </Time>
              </>
            </>
          )}
        </TimeWrapper>
      </div>
      <h3>{address.name}</h3>
      <p>{buildAddress()}</p>
    </Card>
  );
};

export default Address;
