import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useFetch } from "use-http";
import { Button, FieldWrapper } from "../CommonStyledComponents";
import { Modal } from "react-responsive-modal";
import styled from "styled-components";
import ReactTooltip from "react-tooltip";
import * as Tracking from "../../tracking/Tracking";

const LinkTrigger = styled.a`
  width: 100%;
  display: block;
  text-align: center;
  color: #0049d6;

  &.disabled {
    color: #808080;
  }
`;

const WarningMessage = styled.p`
  background: #feefea;
  border-radius: 8px;
  letter-spacing: -0.3px;
  color: #f95b29;
  padding: 16px;
`;

const ModalContentWrapper = styled.div`
  text-align: center;

  h2 {
    margin-bottom: 13px;
  }

  p {
    line-height: 24px;
    margin-bottom: 24px;
  }

  .msg {
    margin: 16px;
  }
`;

const FieldWrapperStyled = styled(FieldWrapper)`
  label {
    text-align: left;
  }
`;

const DoNotDeliver = ({
  triggerType = "button",
  triggerText,
  modalTitle,
  warningText,
  reason,
  disabled,
}) => {
  // triggerType - "link", "button"
  // reason - "UNKNOWN_REASON", "WRONG_DELIVERY_INFO_REASON", "SCHEDULE_ISSUE_REASON"

  const { t } = useTranslation();

  const [modalOpen, setModalOpen] = useState(false);
  const [comment, setComment] = useState("");
  const [message, setMessage] = useState(null);

  const { trackingID } = useParams();

  const options = {
    cachePolicy: "no-cache",
    mode: "cors",
  };

  const { response: responseV2, post: postV2 } = useFetch(
    `${process.env.REACT_APP_API_URL}/v2/stop`,
    options
  );

  const onSendClick = async () => {
    setMessage(null);

    if (comment === null || comment === "") {
      let msg = t("enter_comment");

      setMessage(msg);

      return;
    }

    const body = {
      reason: reason,
      comment: comment,
    };

    await postV2(`${trackingID}/stop-delivery`, body);

    let msg = "";

    if (responseV2.ok) {
      msg = t("on_hold_success");
    } else {
      msg = t("on_hold_fail");
    }

    setMessage(msg);

    const isButton = triggerType === "button";

    if (isButton) {
      Tracking.trackEvent(Tracking.TrackingEvent.DoNotDeliverReported);
    } else {
      Tracking.trackEvent(Tracking.TrackingEvent.SomethingWrongReported);
    }
  };

  const getTriggerLabel = () => {
    const defaultText = t("do_not_deliver");

    return !!triggerText ? triggerText : defaultText;
  };

  const getModalLabel = () => {
    const defaultText = t("do_not_deliver");

    return !!modalTitle ? modalTitle : defaultText;
  };

  const getWarningText = () => {
    const defaultText = t("do_not_deliver_warning");

    return !!warningText ? warningText : defaultText;
  };

  const showModal = () => {
    const isButton = triggerType === "button";

    if (disabled) {
      if (isButton) {
        Tracking.trackEvent(
          Tracking.TrackingEvent.DoNotDeliverClickedWhenDisabled
        );
      } else {
        Tracking.trackEvent(
          Tracking.TrackingEvent.SomethingWrongClickedWhenDisabled
        );
      }

      return;
    }

    setModalOpen(true);

    if (isButton) {
      Tracking.trackEvent(Tracking.TrackingEvent.DoNotDeliverClicked);
    } else {
      Tracking.trackEvent(Tracking.TrackingEvent.SomethingWrongClicked);
    }
  };

  return (
    <>
      {triggerType === "link" && (
        <LinkTrigger
          data-multiline={true}
          data-tip={disabled ? t("cant_stop_reason") : ""}
          className={disabled ? "disabled" : ""}
          onClick={() => showModal()}
        >
          {getTriggerLabel()}
        </LinkTrigger>
      )}

      {(triggerType === "button" || !triggerType) && (
        <div
          data-multiline={true}
          data-tip={disabled ? t("cant_stop_reason") : ""}
        >
          <Button
            className={`transparent ${disabled ? "disabled" : ""}`}
            onClick={() => showModal()}
          >
            {getTriggerLabel()}
          </Button>
        </div>
      )}

      <Modal
        open={modalOpen}
        center
        classNames={{
          overlay: "modal-overlay",
          modal: "modal",
        }}
        closeOnOverlayClick={false}
        showCloseIcon={false}
      >
        <ModalContentWrapper>
          <h2>{getModalLabel()}</h2>

          <FieldWrapperStyled>
            <label htmlFor="comment">{t("comment")} *</label>

            <textarea
              name="comment"
              id="comment"
              cols="30"
              rows="3"
              placeholder={t("describe_what_is_wrong")}
              onChange={(e) => setComment(e.target.value)}
            ></textarea>
          </FieldWrapperStyled>

          <WarningMessage>{getWarningText()}</WarningMessage>

          {message !== null && <div className="msg">{message}</div>}

          <Button className="transparent" onClick={() => onSendClick()}>
            {t("send_message")}
          </Button>

          <Button onClick={() => setModalOpen(false)}>{t("cancel")}</Button>
        </ModalContentWrapper>
      </Modal>

      <ReactTooltip />
    </>
  );
};

export default DoNotDeliver;
