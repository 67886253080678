import React, { useState } from "react";
import styled from "styled-components";
import { Modal } from "react-responsive-modal";
import { useTranslation } from "react-i18next";
import Card from "../card/Card";
import { Button } from "../CommonStyledComponents";

const Status = styled.p`
  margin-bottom: 20px;

  .red {
    color: ${(props) => props.theme.colors.flamingo};
  }

  .green {
    color: ${(props) => props.theme.colors.caribbeanGreen};
  }
`;

const StatusDescription = styled.div`
  padding: 15px 26px;
  border-radius: 8px;
  margin-bottom: 20px;
  text-align: center;

  p {
    margin-bottom: 0px;
    font-size: 14px;
    line-height: 21px;
  }

  &.blue {
    background-color: ${(props) => props.theme.colors.selago};

    p {
      color: ${(props) => props.theme.colors.scienceBlue};
    }
  }

  &.red {
    background-color: ${(props) => props.theme.colors.bridesmaid};

    p {
      color: ${(props) => props.theme.colors.flamingo};
    }
  }
`;

const ModalContentWrapper = styled.div`
  text-align: center;

  h2 {
    margin-bottom: 13px;
  }

  p {
    line-height: 24px;
    margin-bottom: 24px;
  }
`;

const DeliveryAlternatives = ({
  contactlessDelivery = false,
  onContactlessChange,
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  const [confirmContactlessModalOpen, setConfirmContactlessModalOpen] =
    useState(false);

  const [confirmRegularModalOpen, setConfirmRegularModalOpen] = useState(false);

  const handleWarningModalReturn = () => {
    console.warn("Returning orders is currently not possible");
    setModalOpen(false);
  };

  const { t } = useTranslation();

  return (
    <>
      <Card>
        <h3>{t("no_contact_delivery")}</h3>

        <Status>
          {t("currently")}:&nbsp;
          <span className={contactlessDelivery ? "green" : "red"}>
            {contactlessDelivery ? t("allowed") : t("not_allowed")}
          </span>
        </Status>

        <StatusDescription className={contactlessDelivery ? "blue" : "red"}>
          <p>
            {contactlessDelivery
              ? t("contactless_delivery_description")
              : t("regular_delivery_description")}
          </p>
        </StatusDescription>

        {contactlessDelivery ? (
          <Button
            className="transparent"
            onClick={() => setConfirmRegularModalOpen(true)}
          >
            {t("forbid_contactless")}
          </Button>
        ) : (
          <Button onClick={() => setConfirmContactlessModalOpen(true)}>
            {t("allow_contactless")}
          </Button>
        )}
      </Card>

      <Modal
        open={modalOpen}
        center
        classNames={{
          overlay: "modal-overlay",
          modal: "modal",
        }}
        closeOnOverlayClick={false}
        showCloseIcon={false}
      >
        <ModalContentWrapper>
          <h2>{t("warning")}</h2>
          <p>{t("are_you_sure_not_at_home")}</p>
          <Button onClick={() => setModalOpen(false)}>
            {t("do_not_return")}
          </Button>
          <Button
            className="transparent"
            onClick={() => handleWarningModalReturn()}
          >
            {t("return")}
          </Button>
        </ModalContentWrapper>
      </Modal>

      <Modal
        open={confirmContactlessModalOpen}
        center={true}
        classNames={{
          overlay: "modal-overlay",
          modal: "modal",
        }}
        closeOnOverlayClick={true}
        showCloseIcon={false}
        onClose={() => setConfirmContactlessModalOpen(false)}
      >
        <ModalContentWrapper>
          <h2>{t("are_you_sure")}</h2>

          <p>{t("contactless_description")}</p>

          <Button
            className="transparent"
            onClick={() => {
              setConfirmContactlessModalOpen(false);
            }}
          >
            {t("cancel")}
          </Button>

          <Button
            onClick={() => {
              onContactlessChange(true);
              setConfirmContactlessModalOpen(false);
            }}
          >
            {t("continue")}
          </Button>
        </ModalContentWrapper>
      </Modal>

      <Modal
        open={confirmRegularModalOpen}
        center={true}
        classNames={{
          overlay: "modal-overlay",
          modal: "modal",
        }}
        closeOnOverlayClick={true}
        showCloseIcon={false}
        onClose={() => setConfirmRegularModalOpen(false)}
      >
        <ModalContentWrapper>
          <h2>{t("are_you_sure")}</h2>

          <p>{t("regular_description")}</p>

          <Button
            className="transparent"
            onClick={() => {
              setConfirmRegularModalOpen(false);
            }}
          >
            {t("cancel")}
          </Button>

          <Button
            onClick={() => {
              onContactlessChange(false);
              setConfirmRegularModalOpen(false);
            }}
          >
            {t("continue")}
          </Button>
        </ModalContentWrapper>
      </Modal>
    </>
  );
};

export default DeliveryAlternatives;
