import React from "react";
import styled, { keyframes } from "styled-components";

const radius = 5;
const strokeWidth = 10;
const adjustedCircumference = radius * 2 * Math.PI;

const Chart = styled.svg`
  margin: 0 auto;
  transform: rotate(-90deg);
  background: #ffffff;
  border-radius: 50%;
  display: block;
  margin-right: 2px;
`;

const spin = keyframes`
    from {
      stroke-dashoffset:  ${adjustedCircumference}
    }
    to {
      stroke-dashoffset:  0;
    }
`;

const Circle = styled.circle`
  fill: #83adff;
  stroke: #0049d6;
  stroke-width: ${strokeWidth};
  stroke-dasharray: ${adjustedCircumference} ${adjustedCircumference};
  stroke-dashoffset: ;
  animation: ${spin} 60s linear infinite;
`;

const PieIcon = () => {
  return (
    <Chart width="15" height="15">
      <Circle r={radius} cx="7.5" cy="7.5" />
    </Chart>
  );
};

export default PieIcon;
