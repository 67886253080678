import React, { useState } from "react";
import styled from "styled-components";
import { SpinnerCircular } from "spinners-react";
import { Img } from "react-image";
import { Modal } from "react-responsive-modal";
import ImageUploading from "react-images-uploading";
import { useTranslation } from "react-i18next";
import { Button, PlusIcon } from "../CommonStyledComponents";
import imagePlaceholder from "../../images/image-regular.svg";
import ImagesList from "../imagesList/ImagesList";
import * as Tracking from "../../tracking/Tracking";

const UploadButton = styled.button`
  min-width: 80px;
  height: 80px;
  border: 2px solid #83adff;
  box-sizing: border-box;
  border-radius: 8px;
  background-position: center;
  transition: background 0.8s;
  transition: box-shadow 0.3s;
  margin-left: 5px;
  margin-right: 4px;

  :hover {
    background: ${(props) => props.theme.colors.aquaHaze}
      radial-gradient(
        circle,
        transparent 1%,
        ${(props) => props.theme.colors.aquaHaze} 1%
      )
      center/15000%;
    box-shadow: 0 0 5px ${(props) => props.theme.colors.resolutionBlue};
  }

  :active {
    background-color: #6eb9f7;
    background-size: 100%;
    transition: background 0s;
  }
`;

const InnerModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ImageButton = styled(Button)`
  width: 150px;
`;

const UploadImg = styled(Img)`
  max-height: 150px;
  border-radius: 8px;
`;

const ImageActionRow = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
`;

const StopPhotos = ({ images, saveImage }) => {
  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const [uploadImages, setUploadImages] = useState([]);

  const onChange = (imageList) => {
    // data for submit
    setUploadImages(imageList);
  };

  const save = (image) => {
    saveImage(image);
    setUploadImages([]);
    setUploadModalOpen(false);

    Tracking.trackEvent(Tracking.TrackingEvent.PhotoUploaded);
  };

  const { t } = useTranslation();

  return (
    <>
      <h3>{t("photos_title")}</h3>

      <ImagesList images={images}>
        <UploadButton onClick={() => setUploadModalOpen(true)}>
          <PlusIcon />
        </UploadButton>
      </ImagesList>

      <Modal
        open={uploadModalOpen}
        onClose={() => {
          setUploadModalOpen(false);
          setUploadImages([]);
        }}
        center
        classNames={{
          overlay: "modal-overlay",
          modal: "modal",
        }}
      >
        <InnerModalWrapper>
          <ImageUploading
            value={uploadImages}
            onChange={onChange}
            acceptType={["jpg", "png"]}
            maxFileSize={5000000}
          >
            {({ imageList, onImageUpload, errors }) => (
              <>
                {imageList.length ? (
                  imageList.map((image) => (
                    <div key={image.dataURL} className="image-item">
                      <UploadImg
                        src={image.dataURL}
                        loader={<SpinnerCircular />}
                      />
                    </div>
                  ))
                ) : (
                  <UploadImg src={imagePlaceholder} />
                )}
                {errors?.acceptType && (
                  <span>
                    {t("the_selected_file_is_of_an_unsuppoerted_type")}
                  </span>
                )}
                {errors?.maxFileSize && (
                  <span>{t("the_selected_file_exceeds_size")}</span>
                )}
                <ImageActionRow>
                  <ImageButton onClick={onImageUpload}>
                    {t("select_image")}
                  </ImageButton>
                  <ImageButton
                    onClick={() => save(uploadImages[0])}
                    disabled={!imageList.length || errors}
                  >
                    {t("save_image")}
                  </ImageButton>
                </ImageActionRow>
              </>
            )}
          </ImageUploading>
        </InnerModalWrapper>
      </Modal>
    </>
  );
};

export default StopPhotos;
