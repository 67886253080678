
import LANG_DA from './da.json'
import LANG_NO from './no.json'
import LANG_EN from './en.json'

const languages = {
  LANG_DA,
  LANG_NO,
  LANG_EN,
}

export default languages;
