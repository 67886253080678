import React from "react";
import Map from "../map/Map";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import Card from "../card/Card";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import closeIcon from "../../images/close-icon.svg";
import phone from "../../images/phone.svg";
import phoneWhite from "../../images/phone-white.svg";
import location from "../../images/location.svg";
import mapBg from "../../images/map-bg.jpg";
import locationWhite from "../../images/location-white.svg";
import * as Tracking from "../../tracking/Tracking";

const MobileMapContainer = styled.div`
  .map-container {
    height: 400px;
    width: 100%;
    display: block;
  }
`;

const DriverInfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const PhoneIcon = styled.span`
  display: inline-block;
  width: 20px;
  height: 20px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px 20px;
  background-image: url("${phone}");
`;

const PhoneButton = styled.a`
  padding: 5px 30px 4px 30px;
  border-radius: 16px;
  border: 2px solid #83adff;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;

  &:hover {
    background: #0049d6;
    border-color: #0049d6;
    transition: all 0.3s ease;

    ${PhoneIcon} {
      background-image: url("${phoneWhite}");
    }
  }
`;

const DriverName = styled.p`
  margin-bottom: 7px;
`;

const CarrierName = styled.p`
  margin-top: 10px;
`;

const DriverCard = styled(Card)`
  .location-details {
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
    letter-spacing: -0.3px;
    color: ${(props) => props.theme.colors.scienceBlue};
    position: relative;
    padding-left: 27px;

    .location-icon {
      display: inline-block;
      width: 22px;
      height: 22px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 22px 22px;
      position: absolute;
      left: 0px;
      top: calc(50% - 11px);
      background-image: url("${location}");
      left: 0px;
    }
  }

  .map-wrapper {
    border-radius: 6px;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 184px;

    & > div {
      height: 100%;
    }

    .map-container {
      height: 100%;
    }

    .mobile-overlay {
      position: absolute;
      left: 0px;
      top: 0px;
      width: 100%;
      height: 100%;
      background: url("${mapBg}");
      background-size: 100%;
      background-position: center;
      background-repeat: no-repeat;
      justify-content: center;
      align-items: center;
      display: none;
    }

    .location-details {
      font-size: 14px;
      line-height: 21px;
      font-weight: 500;
      letter-spacing: -0.3px;
      color: ${(props) => props.theme.colors.white};
      width: 100%;
      display: block;
      padding: 20px;
      line-height: 36px;

      .location-icon {
        display: inline-block;
        width: 24px;
        height: 24px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 22px 22px;
        background-image: url("${locationWhite}");
        left: 0px;
        margin-right: 5px;
        position: relative;
        left: 0px;
        top: 7px;
      }
    }
  }

  @media screen and (max-width: 39.9375em) {
    .map-wrapper {
      height: 73px;
    }
    .location-details {
      display: none;
    }
    .map-wrapper .mobile-overlay {
      display: flex;
    }
    .map-wrapper .location-details {
      &:after {
        content: "";
        width: 12px;
        height: 12px;
        position: absolute;
        right: 20px;
        top: calc(50% - 6px);
        background-position: center;
        background-repeat: no-repeat;
        background-size: auto 10px;
        background-image: url("../images/arrow-right-white.svg");
      }
    }
  }
`;

const shouldRenderPhoneNumber = (driverDetails) =>
  driverDetails &&
  driverDetails.canCall &&
  (driverDetails.phone ?? "").length > 0;

const DriverDetails = ({
  driverDetails,
  deliveryLng,
  deliveryLat,
  pickupLng,
  pickupLat,
  showDelivery,
  carrierName,
}) => {
  const [isOpen, setOpen] = React.useState(false);

  const { t } = useTranslation();

  const onPhoneClick = () => {
    Tracking.trackEvent(Tracking.TrackingEvent.DriverCalled);
  };

  return (
    <DriverCard>
      <DriverInfoWrapper>
        <div>
          <h3>{t("driver_details_title")}</h3>

          {shouldRenderPhoneNumber(driverDetails) && (
            <DriverName>{driverDetails.name}</DriverName>
          )}

          {driverDetails.location.close && (
            <div className="location-details">
              <span className="location-icon"></span>
              {t("within_500m")}
            </div>
          )}
        </div>

        {shouldRenderPhoneNumber(driverDetails) && (
          <PhoneButton
            href={`tel:${driverDetails.phone}`}
            onClick={() => onPhoneClick()}
          >
            <PhoneIcon></PhoneIcon>
          </PhoneButton>
        )}
      </DriverInfoWrapper>

      {driverDetails.location.close && (
        <div className="map-wrapper">
          <Map
            lat={driverDetails.location.latitude}
            lng={driverDetails.location.longitude}
            deliveryLng={deliveryLng}
            deliveryLat={deliveryLat}
            pickupLng={pickupLng}
            pickupLat={pickupLat}
            showDelivery={showDelivery}
          />

          <div className="mobile-overlay" onClick={() => setOpen(true)}>
            <div className="location-details">
              <span className="location-icon"></span>
              {t("within_500m")}
            </div>
          </div>

          <Modal
            open={isOpen}
            onClose={() => setOpen(false)}
            center
            classNames={{
              overlay: "modal-overlay",
              modal: "modal",
              closeIcon: { closeIcon },
            }}
          >
            <MobileMapContainer>
              <Map
                lat={driverDetails.location.latitude}
                lng={driverDetails.location.longitude}
                deliveryLng={deliveryLng}
                deliveryLat={deliveryLat}
                pickupLng={pickupLng}
                pickupLat={pickupLat}
                showDelivery={showDelivery}
              />
            </MobileMapContainer>
          </Modal>
        </div>
      )}

      {carrierName && (
        <CarrierName>
          {t("carrier")}: {carrierName}
        </CarrierName>
      )}
    </DriverCard>
  );
};

export default DriverDetails;
