import React from "react";
import { useLottie } from "lottie-react";
import AnimationData from "./loader.json";
import styled from "styled-components";
const LoaderSection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: ${(props) => props.theme.colors.resolutionBlue};
`;
const Loader = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: AnimationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const style = {
    height: 100,
    with: 100,
  };

  const { View } = useLottie(defaultOptions, style);

  return <LoaderSection>{View}</LoaderSection>;
};

export default Loader;
