import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Card from "../card/Card";
import { Button } from "../CommonStyledComponents";
import { saveAs } from "file-saver";
import * as Tracking from "../../tracking/Tracking";

const FreightbillCard = styled(Card)`
  a {
    margin-bottom: 0;
    text-align: center;
  }

  .file-name {
    color: ${(props) => props.theme.colors.scienceBlue};
  }
`;

const DownloadFreightBill = ({
  pdfFileBase64,
  fileName = "freight_bill_zendera.pdf",
}) => {
  const { t } = useTranslation();

  if (!pdfFileBase64) {
    return null;
  }

  const downloadFile = async (fileName, base64Data) => {
    // We're using the file-saver package to allow iphone users download file too.

    const base64Response = await fetch(
      `data:application/pdf;base64,${base64Data}`
    );
    const blob = await base64Response.blob();

    saveAs(blob, fileName);

    Tracking.trackEvent(Tracking.TrackingEvent.FreightbillDownloaded);
  };

  return (
    <FreightbillCard>
      <h3>Freight bill</h3>

      <p>
        Attached file: <span className="file-name">{fileName}</span>
      </p>

      <Button
        onClick={() => downloadFile(fileName, pdfFileBase64)}
        className="transparent"
      >
        {t("download_freight_bill")}
      </Button>
    </FreightbillCard>
  );
};

export default DownloadFreightBill;
