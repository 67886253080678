import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import zenderaLogo from "../../images/zendera.svg";
import completed from "../../images/completed.svg";
import exclamationmark from "../../images/exclamationmark.svg";

const Head = styled.header`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  padding: 25px 100px 15px;
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    padding: 25px 25px 15px;
  }
  @media screen and (max-width: 39.9375em) {
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const CarrierLogo = styled.div`
  margin-bottom: 15px;
  img {
    width: 166px;
  }
  @media screen and (max-width: 39.9375em) {
    img {
      width: 130px;
    }
  }
`;

const LogoCourierId = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  #powered-by-text {
    color: ${(props) => props.theme.colors.pigeonPost};
    margin-right: 4px;
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 4px;
    > img {
      width: 69px;
      margin-left: 5px;
    }
  }
  #courier-id {
    color: #052769;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
  }
`;

const Completed = styled.div`
  text-align: center;
  margin: 2rem;
  margin-top: 0;
  font-size: 20px;
  line-height: 29px;
  font-weight: 700;
  color: #052769;

  img {
    margin: 1rem;
  }
`;

const Header = ({
  serialNumber,
  showComplete = false,
  showVisitAttempted = false,
  completeText = null,
  visitAttemptedText = null,
  showCarrier = false,
  carrierLogo,
  carrierName,
}) => {
  const { t } = useTranslation();

  if (completeText === null) {
    completeText = t("order_delivered_successfully");
  }

  return (
    <Head className="medium-6">
      {showCarrier && (
        <CarrierLogo>
          {carrierLogo ? (
            <img src={carrierLogo} alt={carrierName} />
          ) : (
            <h2>{carrierName}</h2>
          )}
        </CarrierLogo>
      )}

      {showComplete && !showVisitAttempted && (
        <Completed>
          <img src={completed} alt={completeText}></img>
          <div>{completeText}</div>
        </Completed>
      )}

      {showVisitAttempted && (
        <Completed>
          <img src={exclamationmark} alt={visitAttemptedText}></img>
          <div>{visitAttemptedText}</div>
        </Completed>
      )}

      <LogoCourierId>
        <p id="courier-id">{`#${serialNumber}`}</p>
        <span id="powered-by-text">
          {t("powered_by")}
          <img src={zenderaLogo} alt="Zendera logo" />
        </span>
      </LogoCourierId>
    </Head>
  );
};

export default Header;
