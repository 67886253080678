import React from "react";
import Theme from "./Theme";
import "./App.scss";
import * as Tracking from "./tracking/Tracking";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// needs to be bundled
import "./i18n";

// Import components
import OrderPage from "./pages/OrderPage";
import StopPage from "./pages/StopPage";
import HomePage from "./pages/HomePage";

Tracking.initTracking();

function App() {
  return (
    <Theme>
      <Router>
        <Switch>
          <Route path={`/stop/:trackingID`} component={StopPage} />
          <Route path={`/:trackingID`} component={OrderPage} />
          <Route exact path={`/`} component={HomePage}></Route>
        </Switch>
      </Router>
    </Theme>
  );
}

export default App;
