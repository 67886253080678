import * as amplitude from "@amplitude/analytics-browser";

export class AmplitudeTracking {
  constructor(config) {
    this.init(config);
  }

  init(config) {
    amplitude.init("cfe1a081c386996648c4a1b6f34ffd7", {
      serverZone: "EU",
      defaultTracking: {
        pageViews: false,
        attribution: true,
        sessions: true,
        fileDownloads: true,
        formInteractions: true,
      },
      ...config,
    });
  }

  track(event, properties) {
    amplitude.track(event, properties);
  }
}

export default AmplitudeTracking;
