import React, { Component } from "react";
import mapboxgl from "mapbox-gl";

import driver from "../../images/driver-location-icon.svg";
import pickupPin from "../../images/PickupPin.svg";
import deliveryPin from "../../images/DeliveryPin.svg";
class Map extends Component {
  constructor(props) {
    super(props);
    this.state = {
      zoom: 13,
    };
    mapboxgl.accessToken = process.env.REACT_APP_MAP_BOX_TOKEN;
  }

  componentDidMount() {
    let lat;
    let lng;
    if (this.props.lat || this.props.lng) {
      lng = this.props.lng;
      lat = this.props.lat;
    } else {
      if (this.props.showDelivery) {
        lng = this.props.deliveryLng;
        lat = this.props.deliveryLat;
      } else {
        lng = this.props.pickupLng;
        lat = this.props.pickupLat;
      }
    }

    let map = new mapboxgl.Map({
      container: this.mapContainer,
      style: "mapbox://styles/mapbox/streets-v11",
      center: [lng, lat],
      zoom: this.state.zoom,
    });

    let apiURL = `https://api.mapbox.com/directions/v5/mapbox/driving/${
      lng
    },${lat};${
      this.props.showDelivery ? this.props.deliveryLng : this.props.pickupLng
    },${
      this.props.showDelivery ? this.props.deliveryLat : this.props.pickupLat
    }?steps=true&geometries=geojson&access_token=pk.eyJ1Ijoid2F6aWQtc2hhaCIsImEiOiJja2M1dnI3NHYwMWhuMzRuc3puaXM4dXRyIn0.xqQUGngA3_Gq9T8yHalhmg`;

    map.on("load", async () => {
      let driverImg = new Image(20, 20);
      driverImg.onload = () => map.addImage("driverLogo", driverImg);
      driverImg.src = driver;

      let pickupImg = new Image(20, 20);
      pickupImg.onload = () => map.addImage("pickupPin", pickupImg);
      pickupImg.src = pickupPin;

      let deliveryImg = new Image(20, 20);
      deliveryImg.onload = () => map.addImage("deliveryPin", deliveryImg);
      deliveryImg.src = deliveryPin;

      let res;
      try {
        res = await (await fetch(apiURL)).json();
        let coordinates = res.routes[0].geometry.coordinates;
        let geojson = {
          type: "Feature",
          properties: {
            color: "#33C9EB",
          },
          geometry: {
            type: "LineString",
            coordinates: coordinates,
          },
        };

        if (map.getSource("route")) {
          map.getSource("route").setData(geojson);
        } else {
          // otherwise, make a new request
          map.addLayer({
            id: "route",
            type: "line",
            source: {
              type: "geojson",
              data: {
                type: "Feature",
                properties: {},
                geometry: {
                  type: "LineString",
                  coordinates: coordinates,
                },
              },
            },
            layout: {
              "line-join": "round",
              "line-cap": "round",
            },
            paint: {
              "line-color": "#00A3FF",
              "line-width": 5,
              "line-opacity": 1,
            },
          });
        }

        map.addLayer({
          id: "driver-logo",
          type: "symbol",
          source: {
            type: "geojson",
            data: {
              type: "FeatureCollection",
              features: [
                {
                  type: "Feature",
                  properties: {},
                  geometry: {
                    type: "Point",
                    coordinates: [this.props.lng, this.props.lat],
                  },
                },
              ],
            },
          },
          layout: {
            "icon-image": "driverLogo",
            "icon-rotate": ["get", "bearing"],
            "icon-rotation-alignment": "map",
            "icon-allow-overlap": true,
            "icon-ignore-placement": true,
          },
        });

        if (this.props.showDelivery) {
          map.addLayer({
            id: "delivery-logo",
            type: "symbol",
            source: {
              type: "geojson",
              data: {
                type: "FeatureCollection",
                features: [
                  {
                    type: "Feature",
                    properties: {},
                    geometry: {
                      type: "Point",
                      coordinates: [
                        this.props.deliveryLng,
                        this.props.deliveryLat,
                      ],
                    },
                  },
                ],
              },
            },
            layout: {
              "icon-image": "deliveryPin",
              "icon-rotate": ["get", "bearing"],
              "icon-rotation-alignment": "map",
              "icon-allow-overlap": true,
              "icon-ignore-placement": true,
            },
          });
        } else {
          map.addLayer({
            id: "pickup-logo",
            type: "symbol",
            source: {
              type: "geojson",
              data: {
                type: "FeatureCollection",
                features: [
                  {
                    type: "Feature",
                    properties: {},
                    geometry: {
                      type: "Point",
                      coordinates: [this.props.pickupLng, this.props.pickupLat],
                    },
                  },
                ],
              },
            },
            layout: {
              "icon-image": "pickupPin",
              "icon-rotate": ["get", "bearing"],
              "icon-rotation-alignment": "map",
              "icon-allow-overlap": true,
              "icon-ignore-placement": true,
            },
          });
        }
      } catch (err) {
        console.error("Map instantiation failed: ", err);
      }
    });
  }

  render() {
    return (
      <div ref={(el) => (this.mapContainer = el)} className="map-container" />
    );
  }
}

export default Map;
