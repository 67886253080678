import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import Card from "../card/Card";
import ImagesList from "../imagesList/ImagesList";

const Block = styled.div`
  margin-bottom: 1rem;

  div {
    color: ${(props) => props.theme.colors.midNight};
  }

  .signature {
    margin-top: 0.5rem;
    border-radius: 13px;
  }
`;

const RegularProof = ({ deliveryProof }) => {
  const { t } = useTranslation();

  return (
    <Card>
      {deliveryProof.signatureUrl && (
        <Block>
          <h3>{t("signature")}</h3>

          <img
            className="signature"
            src={deliveryProof.signatureUrl}
            alt={t("signature")}
          ></img>
        </Block>
      )}

      {deliveryProof.signee && (
        <Block>
          <h3>{t("signee_name")}</h3>

          <div>{deliveryProof.signee}</div>
        </Block>
      )}

      {deliveryProof.imageUrls?.length > 0 && (
        <Block>
          <h3>{t("attachments")}</h3>

          <ImagesList images={deliveryProof.imageUrls}></ImagesList>
        </Block>
      )}

      {deliveryProof.comment && (
        <Block>
          <h3>{t("comment")}</h3>

          <div>{deliveryProof.comment}</div>
        </Block>
      )}
    </Card>
  );
};

export default RegularProof;
