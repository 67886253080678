import styled from "styled-components";
import plusIcon from "../images/plus.svg";
import minusIcon from "../images/minus.svg";
import { Link } from "react-router-dom";

export const FieldWrapper = styled.div`
  label {
    font-size: 14px;
    line-height: 21px;
    color: ${(props) => props.theme.colors.midNight};
    font-weight: 500;
    margin-bottom: 4px;
  }
  input[type="text"],
  input[type="number"] {
    border-radius: 8px;
    box-shadow: inset 0px 2px 4px rgba(3, 47, 133, 0.1);
    border: 1px solid rgba(3, 47, 133, 0.1);
    background-color: ${(props) => props.theme.colors.aquaHaze};
    height: 48px;
    font-size: 14px;
    line-height: 21px;
  }

  textarea {
    border-radius: 8px;
    box-shadow: inset 0px 2px 4px rgba(3, 47, 133, 0.1);
    border: 1px solid rgba(3, 47, 133, 0.1);
    background-color: ${(props) => props.theme.colors.aquaHaze};
    font-size: 14px;
    line-height: 21px;
    padding-top: 14px;
  }
`;

export const Button = styled.button`
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  display: block;
  background-color: ${(props) => props.theme.colors.scienceBlue};
  border-radius: 8px;
  box-shadow: 0px 0px 1px rgba(53, 80, 133, 0.2),
    0px 3px 12px rgba(53, 80, 133, 0.15);
  width: 100%;
  padding: 9px;
  margin-bottom: 14px;
  color: white;
  cursor: pointer;
  &.transparent {
    background-color: transparent;
    color: ${(props) => props.theme.colors.scienceBlue};
    border: 2px solid #83adff;
  }
  &.disabled,
  :disabled {
    background-color: ${(props) => props.theme.colors.mercury};
    color: grey;
    border: 2px solid grey;
    :hover {
      background: ${(props) => props.theme.colors.mercury};
    }
    :active {
      background-color: ${(props) => props.theme.colors.mercury};
    }
  }

  background-position: center;
  transition: background 0.8s;

  :hover {
    background: ${(props) => props.theme.colors.lightBlue}
      radial-gradient(
        circle,
        transparent 1%,
        ${(props) => props.theme.colors.lightBlue} 1%
      )
      center/15000%;
  }
  :active {
    background-color: #6eb9f7;
    background-size: 100%;
    transition: background 0s;
  }
`;

export const TextButton = styled.button`
  line-height: inherit;
  color: #1779ba;
  text-decoration: none;
  cursor: pointer;
  &:hover,
  :focus {
    color: #1468a0;
  }
`;

export const LinkButton = styled(Link)`
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  display: block;
  background-color: ${(props) => props.theme.colors.scienceBlue};
  border-radius: 8px;
  box-shadow: 0px 0px 1px rgba(53, 80, 133, 0.2),
    0px 3px 12px rgba(53, 80, 133, 0.15);
  width: 100%;
  padding: 9px;
  margin-bottom: 14px;
  color: white;

  text-align: center;
  &.transparent {
    background-color: transparent;
    color: ${(props) => props.theme.colors.scienceBlue};
    border: 2px solid #83adff;
  }
  :disabled {
    background-color: ${(props) => props.theme.colors.mercury};
    color: grey;
    border: 2px solid grey;
    :hover {
      background: ${(props) => props.theme.colors.mercury};
    }
    :active {
      background-color: ${(props) => props.theme.colors.mercury};
    }
  }

  background-position: center;
  transition: background 0.8s;

  :hover {
    color: white;
    background: ${(props) => props.theme.colors.lightBlue}
      radial-gradient(
        circle,
        transparent 1%,
        ${(props) => props.theme.colors.lightBlue} 1%
      )
      center/15000%;
  }
  :active {
    background-color: #6eb9f7;
    background-size: 100%;
    transition: background 0s;
  }
`;

export const PlusIcon = styled.div`
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("${plusIcon}");
`;

export const MinusIcon = styled.div`
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("${minusIcon}");
`;

export const dateFormat = "D MMMM, HH:mm";
