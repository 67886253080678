import React, { useEffect } from "react";
import styled from "styled-components";
import { LinkButton } from "../CommonStyledComponents";
import { useTranslation } from "react-i18next";
import * as Tracking from "../../tracking/Tracking";

const NotFoundSection = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: ${(props) => props.theme.colors.resolutionBlue};
  flex-direction: column;
  color: white;
`;

const H1 = styled.h1`
  color: ${(props) => props.theme.colors.white};
  font-weight: 700;
  font-size: 80px;
  line-height: 80px;
`;

const H2 = styled.h2`
  color: ${(props) => props.theme.colors.white};
`;

const P = styled.p`
  color: ${(props) => props.theme.colors.white};
`;

const BackButton = styled(LinkButton)`
  width: 200px;
`;

const NotFound = () => {
  const { t } = useTranslation();

  useEffect(() => {
    Tracking.trackPageViewEvent(Tracking.TrackingEvent.OrderNotFoundError);
  }, []);

  return (
    <NotFoundSection>
      <H1>{t("sorry")}</H1>
      <H2>{t("could_not_find_trace")}</H2>

      <P>{t("double_check_code")}</P>
      <BackButton to="/">{t("go_back")}</BackButton>
    </NotFoundSection>
  );
};

export default NotFound;
