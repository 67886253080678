import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import Card from "../card/Card";
import ImagesList from "../imagesList/ImagesList";

const Block = styled.div`
  margin-bottom: 1rem;

  div {
    color: ${(props) => props.theme.colors.midNight};
  }

  .signature {
    margin-top: 0.5rem;
    border-radius: 13px;
  }
`;

const StyledImagesList = styled(ImagesList)`
  height: 200px;

  img {
    max-width: 280px;
    object-fit: cover;
    border-radius: 13px;
  }

  @media screen and (min-width: 63.9375em) {
    height: 400px;

    img {
      max-width: 600px;
    }
  }
`;

const ContactlessProof = ({ deliveryProof }) => {
  const { t } = useTranslation();

  return (
    <Card>
      {deliveryProof.imageUrls?.length > 0 && (
        <Block>
          <h3>{t("proof_of_delivery")}</h3>

          <StyledImagesList images={deliveryProof.imageUrls}></StyledImagesList>
        </Block>
      )}

      {deliveryProof.comment && (
        <Block>
          <h3>{t("comment")}</h3>

          <div>{deliveryProof.comment}</div>
        </Block>
      )}

      {deliveryProof.signatureUrl && (
        <Block>
          <h3>{t("signature")}</h3>

          <img
            className="signature"
            src={deliveryProof.signatureUrl}
            alt={t("signature")}
          ></img>
        </Block>
      )}

      {deliveryProof.signee && (
        <Block>
          <h3>{t("signee_name")}</h3>

          <div>{deliveryProof.signee}</div>
        </Block>
      )}
    </Card>
  );
};

export default ContactlessProof;
