import TrackingService from "./TrackingService";

export const TrackingEvent = {
  TrackYourOrderViewed: "Track Your Order Viewed",
  ColliChecked: "Colli Checked",
  DeliveryDetailsUpdated: "Delivery Details Updated",
  DeliveryFormChanged: "Delivery Form Changed",
  DetailsPageViewed: "Details Page Viewed",
  DriverCalled: "Driver Called",
  FreightbillDownloaded: "Freightbill Downloaded",
  PhotoUploaded: "Photo Uploaded",
  SomethingWrongClicked: "Something Wrong Clicked",
  SomethingWrongClickedWhenDisabled: "Something Wrong Clicked When Disabled",
  SomethingWrongReported: "Something Wrong Reported",
  DoNotDeliverClicked: "Do Not Deliver Clicked",
  DoNotDeliverClickedWhenDisabled: "Do Not Deliver Clicked When Disabled",
  DoNotDeliverReported: "Do Not Deliver Reported",
  TimeWindowChanged: "Time Window Changed",
  OrderNotFoundError: "Order Not Found Error",
};

export const Property = {
  Environment: "environment",
  OrderSerialNumber: "order_serial_number",
  PageType: "page_type",
  DeliveryForm: "delivery_form",
  PageDomain: "page_domain",
  PageLocation: "page_location",
  PagePath: "page_path",
  PageTitle: "page_title",
  PageUrl: "page_url",
  Referrer: "referrer",
};

export const PageType = {
  Pickup: "pick-up",
  Delivery: "delivery",
  Order: "order",
  Home: "home",
};

export const Environment = {
  Development: "development",
  Staging: "staging",
  Production: "production",
};

export const DeliveryForm = {
  Contactless: "contactless",
  Standard: "standard",
};

const trackingService = new TrackingService();

export function initTracking(config) {
  trackingService.initTracking(config);
}

// Available events are in TrackingEvent const.
// See all keys for properties in Property const.
export function trackEvent(event, properties) {
  trackingService.trackEvent(event, properties);
}

// Available events are in TrackingEvent const.
// See all keys for properties in Property const.
export function trackPageViewEvent(event, properties) {
  trackingService.trackPageViewEvent(event, properties);
}

// See all keys for properties in Property const.
export function updateGlobalProperties(properties) {
  trackingService.updateGlobalProperties(properties);
}
