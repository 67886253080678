import React from "react";
import { ThemeProvider, createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
        body{
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            background-color: ${(props) => props.theme.colors.aquaHaze};
        }
        
        h1, h2, h3, p {font-family: 'Poppins';}
        h2{font-size: 24px; line-height: 29px; font-weight: 700; color:${(
          props
        ) => props.theme.colors.deepSapphire}; }
        h3{font-size: 18px; line-height: 27px; font-weight: 600; color:${(
          props
        ) => props.theme.colors.darkBlue};}
        p{font-size: 16px; line-height: 17px; font-weight: 400; color: ${(
          props
        ) => props.theme.colors.darkBlue}; margin-bottom: 10px;}

        .modal-overlay{background: rgba(248, 251, 251, 0.8); padding:0px;}
        .modal{box-shadow: 0px 0px 1px rgba(53, 80, 133, 0.2), 0px 3px 12px rgba(53, 80, 133, 0.15); border-radius: 16px; width:90%;}

        @media screen and (max-width: 39.9375em) {
            body {padding-bottom: 80px;}
        }
    `;

const Theme = ({ children }) => {
  const theme = {
    colors: {
      white: "#ffffff",
      offWhite: "#F8FBFB",
      darkBlue: "#021A4A",
      pigeonPost: "#ABBDE0",
      aquaHaze: "#F8FBFB",
      mercury: "#E5E5E5",
      scienceBlue: "#0049D6",
      lightBlue: "#2D9CDB",
      deepSapphire: "#052769",
      resolutionBlue: "#032F85",
      midNight: "#01102D",
      flamingo: "#F95B29",
      caribbeanGreen: "#00CEA9",
      bridesmaid: "#FEEFEA",
      selago: "#E5EDFB",
      budGreen: "#7fb069",
      lightGreen: "#CDDFA0",
    },
  };
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      {children}
    </ThemeProvider>
  );
};

export default Theme;
