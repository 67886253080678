import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useFetch } from "use-http";
import styled from "styled-components";
import Card from "../card/Card";
import { Button } from "../CommonStyledComponents";
import * as dayjs from "dayjs";
import DateNavigation from "./DateNavigation";
import DoNotDeliver from "../doNotDeliver/DoNotDeliver";
import { Modal } from "react-responsive-modal";
import * as Tracking from "../../tracking/Tracking";

const CardStyled = styled(Card)`
  .navigation {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .subtitle {
    font-weight: 600;
    font-size: 16px;
    color: #021a4a;
    margin-top: 24px;
  }

  .red {
    color: ${(props) => props.theme.colors.flamingo};
    margin-bottom: 10px;
  }

  .green {
    color: ${(props) => props.theme.colors.caribbeanGreen};
    margin-bottom: 10px;
  }
`;

const IntervalButton = styled(Button)`
  &.active,
  &:hover {
    background-color: ${(props) => props.theme.colors.scienceBlue};
    border: 2px solid ${(props) => props.theme.colors.scienceBlue};
    color: white;
  }
`;

const CurrentInterval = styled.span`
  color: ${(props) => props.theme.colors.scienceBlue};
  margin-left: 5px;
`;

const StatusDescription = styled.div`
  padding: 15px 26px;
  border-radius: 8px;
  margin-top: 10px;
  margin-bottom: 20px;
  text-align: center;

  p {
    margin-bottom: 0px;
    font-size: 14px;
    line-height: 21px;
  }

  &.red {
    background-color: ${(props) => props.theme.colors.bridesmaid};

    p {
      color: ${(props) => props.theme.colors.flamingo};
    }
  }
`;

const ModalContentWrapper = styled.div`
  text-align: center;

  h2 {
    margin-bottom: 13px;
  }

  p {
    line-height: 24px;
    margin-bottom: 24px;
  }

  .time-window {
    color: ${(props) => props.theme.colors.scienceBlue};
    white-space: nowrap;
  }
`;

const TimeWindows = ({
  stopType,
  timeWindow,
  onTimeWindowUpdated,
  allowChangingDeliveryInterval,
  allowDoNotDeliver,
  isOrderManual,
}) => {
  const { t } = useTranslation();

  const { trackingID } = useParams();

  const [deliveryIntervals, setDeliveryIntervals] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);

  const [selectedInterval, setSelectedInterval] = useState(null);

  const [isSuccess, setIsSuccess] = useState(null);

  const [modalOpen, setModalOpen] = useState(false);

  const options = {
    cachePolicy: "no-cache",
    mode: "cors",
  };

  const {
    response: responseV2,
    get: getV2,
    post: postV2,
  } = useFetch(`${process.env.REACT_APP_API_URL}/v2/stop`, options);

  const loadIntervals = useCallback(async () => {
    if (!trackingID) {
      return;
    }

    const res = await getV2(`${trackingID}/alternative-delivery-intervals`);

    if (responseV2.ok) {
      const newIntervals = res.intervals.map((val) => {
        val.isActive =
          intervalToString(timeWindow.earliest, timeWindow.latest) ===
          twIntervalToString(val);

        return val;
      });

      setDeliveryIntervals(newIntervals);
    } else {
      setDeliveryIntervals([]);
    }
  }, [getV2, responseV2, trackingID]);

  // Get inital data on mount
  useEffect(() => {
    loadIntervals();
  }, [loadIntervals]);

  const onItervalClick = async (interval) => {
    if (interval.isActive) {
      return;
    }

    setSelectedInterval(interval);
    setModalOpen(true);
  };

  const handleIntervalChange = async () => {
    let interval = selectedInterval;

    await postV2(`${trackingID}/change-delivery-interval`, {
      intervalId: interval.id,
    });

    if (responseV2.ok) {
      const newIntervals = deliveryIntervals.map((val) => {
        val.isActive = val.id === interval.id;

        return val;
      });

      setDeliveryIntervals(newIntervals);
      setIsSuccess(true);

      Tracking.trackEvent(Tracking.TrackingEvent.TimeWindowChanged);
    } else {
      setIsSuccess(false);
    }

    setModalOpen(false);
    setSelectedInterval(null);

    onTimeWindowUpdated();
  };

  const twIntervalToString = (twInterval) => {
    let interval = convertTWInterval(twInterval);
    return intervalToString(interval.start, interval.end);
  };

  const intervalToString = (startString, endString) => {
    let start = dayjs(startString);
    let end = dayjs(endString);

    let startDate = fDate(start);
    let startTime = fTime(start);

    let endDate = fDate(end);
    let endTime = fTime(end);

    if (start.isSame(end, "day")) {
      return `${startDate} ${startTime} - ${endTime}`;
    }

    return `${startTime} ${startDate} - ${endTime} ${endDate}`;
  };

  const fDate = (date) => {
    let dateFormat = "D.MM.YY";

    let isToday = date.isSame(dayjs(), "day");

    if (isToday) {
      return t("today");
    }

    let isTomorrow = date.isSame(dayjs().add(1, "day"), "day");

    if (isTomorrow) {
      return t("tomorrow");
    }

    return date.format(dateFormat);
  };

  const fTime = (date) => {
    let timeFormat = "HH:mm";

    return date.format(timeFormat);
  };

  const getIntervals = () => {
    return (deliveryIntervals ?? []).map((interval) =>
      convertTWInterval(interval)
    );
  };

  const isBetween = (date, start, end) => {
    let _date = dayjs(date);
    let _start = dayjs(start);
    let _end = dayjs(end);

    return (
      (_date.isAfter(_start) || _date.isSame(_start, "day")) &&
      (_date.isBefore(_end) || _date.isSame(_end, "day"))
    );
  };

  const convertTWInterval = (interval) => {
    let start =
      stopType === "pickup"
        ? interval.pickupEarliest
        : interval.deliveryEarliest;

    let end =
      stopType === "pickup" ? interval.pickupLatest : interval.deliveryLatest;

    return {
      start: new Date(start),
      end: new Date(end),
    };
  };

  return (
    <>
      <CardStyled>
        <h3>{t("delivery_alternatives")}</h3>

        {isOrderManual && (
          <StatusDescription className="red">
            <p>{t("on_hold_description")}</p>
          </StatusDescription>
        )}

        {!isOrderManual && (
          <>
            <div>
              {t("currently")}:
              <CurrentInterval>
                {intervalToString(timeWindow.earliest, timeWindow.latest)}
              </CurrentInterval>
            </div>

            {allowChangingDeliveryInterval && (
              <div>
                <div className="subtitle">{t("change_time_window")}:</div>

                <DateNavigation
                  className={"navigation"}
                  intervals={getIntervals()}
                  onDateChange={(date) => {
                    setSelectedDate(date);
                  }}
                ></DateNavigation>

                {isSuccess !== null ? (
                  <div className={isSuccess ? "green" : "red"}>
                    {isSuccess ? t("tw_update_success") : t("tw_update_fail")}
                  </div>
                ) : null}

                {deliveryIntervals
                  ?.filter((i) => {
                    let interval = convertTWInterval(i);
                    return isBetween(
                      selectedDate,
                      interval.start,
                      interval.end
                    );
                  })
                  .map((interval) => (
                    <IntervalButton
                      className={
                        "transparent " + (interval.isActive ? "active" : "")
                      }
                      key={interval.id}
                      onClick={() => onItervalClick(interval)}
                    >
                      {twIntervalToString(interval)}
                    </IntervalButton>
                  ))}

                {deliveryIntervals.length === 0 && (
                  <span>{t("no_intervals")}</span>
                )}
              </div>
            )}

            <hr></hr>

            <DoNotDeliver
              reason={"SCHEDULE_ISSUE_REASON"}
              disabled={!allowDoNotDeliver}
            ></DoNotDeliver>
          </>
        )}
      </CardStyled>

      <Modal
        open={modalOpen}
        center
        classNames={{
          overlay: "modal-overlay",
          modal: "modal",
        }}
        closeOnOverlayClick={false}
        showCloseIcon={false}
      >
        <ModalContentWrapper>
          <h2>{t("are_you_sure")}</h2>

          <p>
            {t("change_tw_warning")} "
            <span className="time-window">
              {selectedInterval ? twIntervalToString(selectedInterval) : ""}
            </span>
            "
          </p>

          <Button className="transparent" onClick={() => setModalOpen(false)}>
            {t("cancel")}
          </Button>

          <Button onClick={() => handleIntervalChange()}>
            {t("continue")}
          </Button>
        </ModalContentWrapper>
      </Modal>
    </>
  );
};

export default TimeWindows;
