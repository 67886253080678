import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import languages from './locales'

// Following guide: https://dev.to/adrai/how-to-properly-internationalize-a-react-application-using-i18next-3hdb

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: process.env.REACT_APP_DEBUG_I18N === "true",
    fallbackLng: 'en',
    interpolation: {
      escape: false,
    },
    resources: {
      'en': languages.LANG_EN,
      'da': languages.LANG_DA,
      'no': languages.LANG_NO, // Generalized Norwegian
      'nb': languages.LANG_NO, // Support Norwegian Bokmål
      'nn': languages.LANG_NO // SUpport Norwegian Nynorsk
    }
  });

export default i18n;
