import React from "react";
import dayjs from "dayjs";
import { dateFormat } from "../CommonStyledComponents";
import styled from "styled-components";
import tick from "../../images/tick.svg";
import small_exclamationmark from "../../images/small_exclamationmark.svg";
import arrowUp from "../../images/arrow-up.svg";

const StatusWrapper = styled.div`
  background: transparent;
  li:last-child::after {
    display: none;
  }

  @media screen and (max-width: 39.9375em) {
    & {
      display: block;
      width: 100%;
      background-color: ${(props) => props.theme.colors.resolutionBlue};
      position: relative;
      z-index: 1;
      padding: 20px;
      padding-left: 60px;
      color: ${(props) => props.theme.colors.white};
      display: none;
      border-radius: 16px 16px 0px 0px;
      margin: 0px;
    }
  }
`;

const AccordionItem = styled.li`
  position: relative;
  padding-bottom: 20px;
  &:after {
    content: "";
    position: absolute;
    width: 4px;
    height: 100%;
    left: -32px;
    top: 2px;
    z-index: 0;
    background: #0049d6;
  }
  &.state {
    &::before {
      content: "";
      display: inline-block;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 4px solid ${(props) => props.theme.colors.scienceBlue};
      background: #0049d6;
      position: absolute;
      left: -40px;
      top: 2px;
      z-index: 2;
    }
    &.completed {
      &::before {
        border: 4px solid #00cea9;
        top: 2px;
        background-color: #00cea9;
        background-image: url("${tick}");
        background-position: center;
        background-size: 10px 8px;
        background-repeat: no-repeat;
        z-index: 2;
      }
      &::after {
        background: #00cea9;
      }
    }
    &.failed {
      &::before {
        border: 4px solid #f95b29;
        top: 2px;
        background-color: #f95b29;
        background-image: url("${small_exclamationmark}");
        background-position: center;
        background-size: 10px 8px;
        background-repeat: no-repeat;
        z-index: 2;
      }
      &::after {
        background: #f95b29;
      }
    }
    &.current {
      &::before {
        border: 4px solid #00cea9;
        top: 2px;
        background-color: #00cea9;
        z-index: 2;
      }
    }
  }
  :last-child:not(.is-active) > .accordion-title {
    border: 0px;
  }
  :last-child > .accordion-content:last-child {
    border: 0px;
    border-color: transparent;
  }
`;
const AccordionContent = styled.div`
  background: transparent;
  border: 0px;
  padding: 0px;
  display: block;
  overflow: hidden;
  border: 0px;
  p {
    color: #ffffff;
    font-size: 14px;
    letter-spacing: -0.3px;
    color: #bdd4ff;
    padding-right: 40px;
    line-height: 20px;
  }
`;

const AccordionTitle = styled.div`
  text-decoration: none;
  border: 0px;
  color: #ffffff;
  padding: 0px;
  font-size: 16px;
  line-height: 24px;
  &:hover,
  &:focus {
    background: transparent;
    color: #ffffff;
    cursor: pointer;
  }
  &::before {
    content: "";
    background-image: url("${arrowUp}");
    transform: rotate(180deg);
    background-position: center;
    background-size: 10px;
    background-repeat: no-repeat;
    width: 10px;
    height: 10px;
    right: 0px;
  }
  .is-active > &::before {
    transform: rotate(0deg);
    content: "";
  }
`;

const Time = styled.div`
  display: inline-block;
  letter-spacing: -0.3px;
  color: #bdd4ff;
  background-color: #001f5c;
  border-radius: 3px;
  padding: 2px 4px;
  font-size: 14px;
  font-weight: normal;
`;

const statusMappings = {
  pending: { received: "completed" },
  planned: { received: "completed", pickUp: "pending", delivery: "pending" },
  assigned: { received: "completed", pickUp: "pending", delivery: "pending" },
  in_transit: {
    received: "completed",
    pickUp: "completed",
    delivery: "pending",
  },
  delivered: {
    received: "completed",
    pickUp: "completed",
    delivery: "completed",
  },
  completed: {
    received: "completed",
    pickUp: "completed",
    delivery: "completed",
  },
  cancelled: { received: "completed", orderCancelled: "failed" },
  pending_pickup_rescheduling: {
    received: "completed",
    pickUpAttempted: "failed",
    reschedulePickUp: "pending",
  },
  pickup_rescheduled: {
    received: "completed",
    pickUpAttempted: "failed",
    pickUp: "pending",
    delivery: "pending",
  },
  returned_to_origin: {
    received: "completed",
    pickUp: "completed",
    deliveryAttempted: "failed",
    deliveryReturned: "failed",
  },
  pending_delivery_rescheduling: {
    received: "completed",
    pickUp: "completed",
    deliveryAttempted: "failed",
    rescheduleDelivery: "pending",
  },
  delivery_rescheduled: {
    received: "completed",
    pickUp: "completed",
    deliveryAttempted: "failed",
    delivery: "pending",
  },
};

const renderStatusItem = (status, state, date, t) => (
  <AccordionItem className={`state ${state} accordion-item`} key={status}>
    <AccordionTitle className="accordion-title">{t(status)}</AccordionTitle>
    {date && <Time>{dayjs(date).format(dateFormat)}</Time>}
    <AccordionContent className="accordion-content">
      <p>{t(`description_${status}_${state}`)}</p>
    </AccordionContent>
  </AccordionItem>
);

const Status = ({ t, status }) => {
  const currentStatusMapping =
    statusMappings[status.DisplayOrderStatus] || statusMappings["pending"];

  return (
    <StatusWrapper className="accordion status-wrapper">
      {Object.entries(currentStatusMapping).map(([status, state]) =>
        renderStatusItem(status, state, status[status], t)
      )}
    </StatusWrapper>
  );
};

export default Status;
