import React from "react";
import Accordion from "accordion-js";
import "accordion-js/dist/accordion.min.css";
import styled, { keyframes } from "styled-components";
import { withTranslation } from "react-i18next";
import arrowUp from "../../images/arrow-up.svg";
import StatusComponent from "./StatusComponent";
import mobileStatusComplete from "../../images/mobileStatusComplete.svg";
import mobileStatusPending from "../../images/mobileStatusPending.svg";
import mobileStatusFailed from "../../images/mobileStatusFailed.svg";
import mobileStatusCompleteEnd from "../../images/mobileStatusCompleteEnd.svg";
import mobileStatusFailedEnd from "../../images/mobileStatusFailedEnd.svg";

const getCurrentStatusImage = (status) => {
  switch (status.DisplayOrderStatus) {
    case "pending_pickup_rescheduling":
    case "pickup_rescheduled":
    case "pending_delivery_rescheduling":
    case "delivery_rescheduled":
      return "failed";

    case "cancelled":
    case "returned_to_origin":
      return "failedEnd";

    case "pending":
      return "pending";

    case "completed":
    case "delivered":
      return "completedEnd";

    default:
      return "completed";
  }
};

const slideInUp = keyframes`
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
`;

const StatusWrap = styled.div`
  width: 100%;

  @media screen and (max-width: 39.9375em) {
    display: block;
    width: 100%;
    background-color: ${(props) => props.theme.colors.resolutionBlue};
    position: relative;
    z-index: 1;
    color: ${(props) => props.theme.colors.white};
    border-radius: 16px 16px 0px 0px;
    margin: 0px;

    &.open-mobile-status {
      background-color: transparent;

      .status-wrapper {
        display: block;
        animation-duration: 0.3s;
        animation-fill-mode: both;
        animation-name: ${slideInUp};
      }

      .current-status {
        display: none;
      }

      .status-close-overlay {
        height: 100%;
        opacity: 0.8;
      }
    }
  }
`;

const CurrentStatus = styled.div`
  @media screen and (max-width: 39.9375em) {
    & {
      color: ${(props) => props.theme.colors.white};
      font-size: 16px;
      line-height: 24px;
      background-size: auto 100%;
      background-position: left 20px center;
      padding: 18px;
      padding-left: 60px;
      background-repeat: no-repeat;
      position: relative;
      z-index: 1;
      background-color: ${(props) => props.theme.colors.resolutionBlue};
      border-radius: 16px 16px 0px 0px;
      display: block;
      &.pending {
        background-image: url(${mobileStatusPending});
      }
      &.completed {
        background-image: url(${mobileStatusComplete});
      }
      &.failed {
        background-image: url(${mobileStatusFailed});
      }
      &.completedEnd {
        background-image: url(${mobileStatusCompleteEnd});
      }
      &.failedEnd {
        background-image: url(${mobileStatusFailedEnd});
      }
      &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0px;
        top: 0px;
        z-index: -1;
        background-image: url("${arrowUp}");
        background-position: right 20px center;
        background-size: 11px;
        background-repeat: no-repeat;
      }
    }
  }
`;

const StatusCloseOverlay = styled.div`
  @media screen and (max-width: 39.9375em) {
    z-index: 1;
    position: fixed;
    left: 0px;
    top: 0px;
    background: #f8fbfb;
    width: 100%;
    height: 0;
    opacity: 0;
    transition: opacity 0.3s, height 0s;
  }
`;

class Status extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isMobileStatusOpen: false,
    };

    this.toggleStatus = this.toggleStatus.bind(this);
  }

  componentDidMount() {
    new Accordion(".accordion", {
      elementClass: "accordion-item",
      triggerClass: "accordion-title",
      panelClass: "accordion-content",
      showMultiple: true,
    });
  }

  toggleStatus() {
    this.setState({ isMobileStatusOpen: !this.state.isMobileStatusOpen });
  }

  render() {
    const { t, status } = this.props; // Assuming 'status' prop is passed to this component

    const getStatusText = (status) => {
      // todo - it's not clear which translations to use for which status, so adding the only one known.
      switch (status) {
        case "dispatched":
          return t("dispatched");

        default:
          return status;
      }
    };

    return (
      <StatusWrap
        className={this.state.isMobileStatusOpen ? "open-mobile-status" : ""}
      >
        <StatusCloseOverlay
          className="show-for-small-only status-close-overlay"
          onClick={this.toggleStatus}
        ></StatusCloseOverlay>
        <CurrentStatus
          className={`show-for-small-only ${getCurrentStatusImage(
            status
          )} current-status`}
          onClick={this.toggleStatus}
        >
          {getStatusText(status.DisplayOrderStatus)}
        </CurrentStatus>
        <StatusComponent t={t} status={status} />
      </StatusWrap>
    );
  }
}

export default withTranslation()(Status);
