import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Card from "../components/card/Card";
import {
  Button,
  FieldWrapper,
  LinkButton,
} from "../components/CommonStyledComponents";
import * as Tracking from "../tracking/Tracking";

import zenderaLogo from "../images/zendera.svg";

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.resolutionBlue};
  align-items: center;
  justify-content: center;
`;

const Subheading = styled.span`
  color: ${(props) => props.theme.colors.pigeonPost};
  margin-right: 4px;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 4px;
  > img {
    width: 69px;
    margin-left: 5px;
  }
`;

const Row = styled.div`
  display: flex;

  & :last-child {
    margin-left: 5px;
  }
  & :first-child {
    margin-right: 5px;
  }
`;

const HomePage = () => {
  const [trackingID, setTrackingID] = useState("");
  const { t, i18n } = useTranslation();

  useEffect(() => {
    Tracking.updateGlobalProperties({
      [Tracking.Property.OrderSerialNumber]: null,
      [Tracking.Property.PageType]: Tracking.PageType.Home,
    });

    Tracking.trackPageViewEvent(Tracking.TrackingEvent.TrackYourOrderViewed);
  }, []);

  return (
    <Wrapper>
      <Card>
        <h1>{t("title_input_tracking_number")}</h1>
        <label htmlFor="tracking-id">{t("insert_tracking_code")}</label>
        <FieldWrapper>
          <input
            type="text"
            name="tracking-id"
            placeholder={t("what_is_your_tracking_code")}
            value={trackingID}
            onChange={(e) => setTrackingID(e.target.value)}
          ></input>
        </FieldWrapper>
        <Row>
          <LinkButton to={`/${trackingID}`}>{t("track_order")}</LinkButton>
          <LinkButton to={`/stop/${trackingID}`}>{t("track_stop")}</LinkButton>
        </Row>
        <Subheading>
          {t("change_language")}
          <Row>
            <Button onClick={() => i18n.changeLanguage("da")}>
              {t("lang_da")}
            </Button>
            <Button onClick={() => i18n.changeLanguage("nb-NO")}>
              {t("lang_no")}
            </Button>
            <Button onClick={() => i18n.changeLanguage("en-US")}>
              {t("lang_en")}
            </Button>
          </Row>
        </Subheading>
        <Subheading>
          {t("powered_by")}
          <img src={zenderaLogo} alt="Zendera logo" />
        </Subheading>
      </Card>
    </Wrapper>
  );
};

export default HomePage;
