import React, { useState } from "react";
import styled from "styled-components";
import { Modal } from "react-responsive-modal";

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ModalImg = styled.img`
  max-height: 300px;
  border-radius: 8px;
  margin-left: 8px;
`;

const ImageRow = styled.div`
  display: flex;
  align-items: center;
  overflow-x: auto;
  height: 100px;
  padding: 5px;
`;

const StyledImg = styled.img`
  height: 100%;
  border-radius: 11px;
  margin-left: 4px;
  margin-right: 4px;
  transition: box-shadow 0.3s;
  max-width: none;

  :hover {
    box-shadow: 0 0 5px ${(props) => props.theme.colors.resolutionBlue};
  }
`;

const ImagesList = ({ images, children, className }) => {
  const [imageModalOpen, setImageModalOpen] = useState(false);

  const [selectedImage, setSelectedImage] = useState("");

  return (
    <>
      <ImageRow className={className}>
        {children}

        {images?.map((url, index) => (
          <StyledImg
            src={url}
            key={index}
            onClick={() => {
              setSelectedImage(url);
              setImageModalOpen(true);
            }}
          />
        ))}
      </ImageRow>

      <Modal
        open={imageModalOpen}
        focusTrapped={false}
        onClose={() => {
          setImageModalOpen(false);
          setSelectedImage("");
        }}
        center
        classNames={{
          overlay: "modal-overlay",
          modal: "modal",
        }}
      >
        <ModalWrapper>
          <ModalImg src={selectedImage} />
        </ModalWrapper>
      </Modal>
    </>
  );
};

export default ImagesList;
