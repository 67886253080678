import React, { useEffect } from "react";
import styled from "styled-components";
import { Modal } from "react-responsive-modal";
import { useTranslation } from "react-i18next";
import Card from "../card/Card";
import InnerPackageDetails from "../package/InnerPackageDetails";
import * as Tracking from "../../tracking/Tracking";

const Header = styled.h2`
  text-align: center;
  margin-bottom: 1rem;
`;

const ProductsList = ({ products, isOpen, onOverlayClick }) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (isOpen) {
      Tracking.trackEvent(Tracking.TrackingEvent.ColliChecked);
    }
  }, [isOpen]);

  return (
    <Modal
      open={isOpen}
      onClose={() => onOverlayClick()}
      center
      focusTrapped={false}
      classNames={{
        overlay: "modal-overlay",
        modal: "modal",
      }}
      styles={{
        modal: {
          paddingLeft: "0.7rem",
          paddingRight: "0.7rem",
        },
      }}
    >
      <Header>{t("colli_details")}</Header>

      {products ? (
        products.map((product, index) => (
          <InnerPackageDetails
            key={index}
            name={product.name}
            number={product.number}
            weight={product.weight}
            volume={product.volume}
            description={product.description}
          />
        ))
      ) : (
        <Card className="text-center">
          <p className="card-description">{t("no_colli_on_order")}</p>
        </Card>
      )}
    </Modal>
  );
};

export default ProductsList;
