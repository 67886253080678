import React from "react";
import Card from "../card/Card";
import { useTranslation } from "react-i18next";
import { formatVolume } from "../../formatting/volume";
import { formatWeight } from "../../formatting/weight";
import styled from "styled-components";

const ProductCard = styled(Card)`
  border-radius: 8px !important;

  @media screen and (max-width: 39.9375em) {
    h2 {
      font-size: 20px;
    }
  }
`;

const InnerPackageDetails = ({ name, weight, volume, number, description }) => {
  const { t } = useTranslation();

  return (
    <ProductCard className="text-center">
      <h2 style={{ textAlign: "left" }}>{name}</h2>
      <p className="card-description">{description}</p>
      <div className="package-details">
        <div className="detail">
          <span className="icon packages"></span>
          {`${number} ${t("colli")}`}
        </div>
        <div className="detail">
          <span className="icon weight"></span>
          {formatWeight(weight)}
        </div>
        <div className="detail">
          <span className="icon volume"></span>
          {formatVolume(volume)}
        </div>
      </div>
      {/* <div className="right-arrow-icon"></div> */}
    </ProductCard>
  );
};

export default InnerPackageDetails;
