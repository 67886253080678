import React from "react";
import styled from "styled-components";
import volume from "../../images/volume.svg";
import weight from "../../images/weight.svg";
import packages from "../../images/packages.svg";
import rightArrow from "../../images/right-arrow.svg";

const CardWrapper = styled.div`
  padding: 15px 15px 15px 15px;
  background-color: ${(props) => props.theme.colors.white};
  box-shadow: 0px 3px 12px rgba(53, 80, 133, 0.15),
    0px 0px 1px rgba(53, 80, 133, 0.2);
  border-radius: 16px;
  color: ${(props) => props.theme.colors.darkBlue};
  position: relative;
  border: 0px;
  margin-bottom: 15px;
  transition: all 0.3s ease;

  h2 {
    margin: 0px;
  }

  h3 {
    margin-bottom: 3px;
  }

  .card-description {
    color: ${(props) => props.theme.colors.deepSapphire};
    font-size: 18px;
    line-height: 27px;
    font-weight: 500;
    letter-spacing: -0.3px;
  }

  .package-details {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 3px 0px 0px 0px;
    border-top: 2px solid #f8fbfb;
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: -7px;
  }

  .detail {
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.3px;
    color: ${(props) => props.theme.colors.resolutionBlue};
    font-weight: 500;
    margin-right: 15px;

    .icon {
      width: 24px;
      height: 24px;
      display: inline-block;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 24px 24px;
      position: relative;
      top: 6px;
      margin-right: 5px;

      &.volume {
        background-image: url("${volume}");
      }

      &.weight {
        background-image: url("${weight}");
      }

      &.packages {
        background-image: url("${packages}");
      }
    }
  }

  .type-time-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }

  .right-arrow-icon {
    width: 12px;
    height: 12px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto 11px;
    background-image: url("${rightArrow}");
    position: absolute;
    top: 50%;
    right: 16px;
  }
`;

const Card = ({ children, className }) => {
  return <CardWrapper className={className}>{children}</CardWrapper>;
};

export default Card;
