import React from "react";
import styled from "styled-components";

import { FieldWrapper, MinusIcon, PlusIcon } from "../CommonStyledComponents";

const IncrementButton = styled.button`
  background-position: center;
  transition: background 0.8s;

  :hover {
    background: ${(props) => props.theme.colors.aquaHaze}
      radial-gradient(
        circle,
        transparent 1%,
        ${(props) => props.theme.colors.aquaHaze} 1%
      )
      center/15000%;
  }
  :active {
    background-color: #6eb9f7;
    background-size: 100%;
    transition: background 0s;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  input {
    width: 60%;
    margin-right: 8px;
  }
  ${IncrementButton} {
    height: 48px;
    width: 20%;
    border: 2px solid #83adff;
    border-radius: 8px;
    &.minus {
      margin-right: 8px;
    }
  }
`;

const StyledInput = styled.input`
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type="number"] {
    -moz-appearance: textfield;
  }
`;

const Counter = ({ label, placeholder, value, onChange }) => {
  return (
    <FieldWrapper>
      <label htmlFor="counter">{label}</label>
      <ButtonWrapper>
        <StyledInput
          type="number"
          placeholder={placeholder}
          value={Number(value ?? 0)}
          onChange={(e) => onChange(String(e.target.value))}
        />
        <IncrementButton
          onClick={(e) => {
            e.preventDefault();
            onChange(String((Number(value) ?? 0) - 1));
          }}
        >
          <MinusIcon />
        </IncrementButton>
        <IncrementButton
          onClick={(e) => {
            e.preventDefault();
            onChange(String((Number(value) ?? 0) + 1));
          }}
        >
          <PlusIcon />
        </IncrementButton>
      </ButtonWrapper>
    </FieldWrapper>
  );
};

export default Counter;
