import AmplitudeTracking from "./AmplitudeTracking";
import * as Tracking from "./Tracking";

export class TrackingService {
  instance; // Tracking implementation instance. Must implement Tracking interface.
  environment; // Current environment.
  globalProperties; // Properties that have to be sent with each event.

  initTracking(config) {
    this.environment = this.getCurrentEnvironment();

    // To initialize tracking we just need to get a tracker instance.
    this.getInstance(config);

    // Also we can set some global properties available at this point.
    const globalProperties = {
      [Tracking.Property.Environment]: this.environment,
    };

    this.updateGlobalProperties(globalProperties);
  }

  // Returns a single Tracking implementation instance.
  getInstance(config) {
    if (!this.instance) {
      this.instance = this.createInstance(config);
    }

    return this.instance;
  }

  // Creates Tracking implementation instance.
  createInstance(config) {
    return new AmplitudeTracking(config);
  }

  getCurrentEnvironment() {
    const url = window.location.href;

    const stagingUrl = "https://staging.tracking.zenderatms.com/";
    const productionUrl = "https://tracking.zenderatms.com/";

    if (url.startsWith(stagingUrl)) {
      return Tracking.Environment.Staging;
    }

    if (url.startsWith(productionUrl)) {
      return Tracking.Environment.Production;
    }

    return Tracking.Environment.Development;
  }

  // Available events are in TrackingEvent const.
  // See all keys for properties in Property const.
  trackEvent(event, properties) {
    const tracking = this.getInstance();

    const allProperties = {
      ...this.globalProperties,
      ...properties,
    };

    tracking.track(event, allProperties);

    // For debugging purposes we will console.log tracking events in all environments
    // except for production.
    const logEvents = this.environment !== Tracking.Environment.Production;

    if (logEvents) {
      console.log(
        `trackEvent ${event} was sent with properties `,
        allProperties
      );
    }
  }

  // Available events are in TrackingEvent const.
  // See all keys for properties in Property const.
  trackPageViewEvent(event, properties) {
    const pageViewProperties = this.getPageViewProperies();

    const newProperties = {
      ...pageViewProperties,
      ...properties,
    };

    this.trackEvent(event, newProperties);
  }

  // Returns properties that should be sent with each page view event.
  getPageViewProperies() {
    // page_domain: http://tracking.zenderatms.com
    // page_location: https://tracking.zenderatms.com/stop/0e846326-7327-48e4-8d48-8ed206bb7b7e
    // page_path: /stop/0e846326-7327-48e4-8d48-8ed206bb7b7e
    // page_title: Zendera Order Tracking
    // page_url: https://tracking.zenderatms.com/stop/0e846326-7327-48e4-8d48-8ed206bb7b7e

    const res = {
      [Tracking.Property.PageDomain]: window.location.origin,
      [Tracking.Property.PageLocation]: window.location.href,
      [Tracking.Property.PagePath]: window.location.pathname,
      [Tracking.Property.PageTitle]: document.title,
      [Tracking.Property.PageUrl]: window.location.href,
      [Tracking.Property.Referrer]: document.referrer,
    };

    return res;
  }

  // See all keys for properties in Property const.
  updateGlobalProperties(properties) {
    // Global properties are:
    // order_serial_number - number
    // page_type - pick-up | delivery | order | home
    // environment - staging | development | production
    this.globalProperties = { ...this.globalProperties, ...properties };
  }
}

// export interface Tracking {
//   init(config: any): void {}
//   track(eventName: string, properties: any): void {}
// }

export default TrackingService;
