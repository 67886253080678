import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { Button } from "../CommonStyledComponents";
import * as dayjs from "dayjs";
import leftIcon from "./../../images/arrow-left.svg";
import rightIcon from "./../../images/arrow-right.svg";

export const PrevIcon = styled.div`
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("${leftIcon}");
`;

export const NextIcon = styled.div`
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("${rightIcon}");
`;

const NavigationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px solid rgba(53, 80, 133, 0.15);
  filter: drop-shadow(-1px 1px 4px rgba(53, 80, 133, 0.15));
  border-radius: 8px;
  padding: 5px;

  button {
    height: 38px;
    width: 30px;
    border-radius: 5px;
    background: ${(props) => props.theme.colors.scienceBlue};
    margin: 0;
  }

  button[disabled] {
    border-color: #d6d6d6;
  }
`;

const SelectedDate = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #021a4a;
`;

const DateNavigation = ({ className, intervals, onDateChange }) => {
  // intervals has to be an array with {start: Date, end: Date}

  const [options, setOptions] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null); // Date

  const getDatesBetween = (start, end) => {
    const res = [];

    const current = new Date(start);
    current.setHours(0);
    current.setMinutes(0);
    current.setSeconds(0);
    current.setMilliseconds(0);

    while (current <= end) {
      res.push(new Date(current));
      current.setDate(current.getDate() + 1);
    }

    return res;
  };

  const updateSelectedDate = useCallback(
    (date) => {
      setSelectedDate(date);
      onDateChange(date);
    },
    [onDateChange]
  );

  const onNavClick = (diff) => {
    let currentIndex = options.findIndex((date) => {
      return dayjs(date).isSame(dayjs(selectedDate));
    });
    let newIndex = currentIndex + diff;

    if (newIndex < 0 || newIndex >= options.length) {
      return;
    }

    updateSelectedDate(options[newIndex]);
  };

  const hasPrevious = () => {
    let currentIndex = options.findIndex((date) => {
      return dayjs(date).isSame(dayjs(selectedDate));
    });

    return currentIndex > 0;
  };

  const hasNext = () => {
    let currentIndex = options.findIndex((date) => {
      return dayjs(date).isSame(dayjs(selectedDate));
    });

    return currentIndex < options.length - 1;
  };

  const prepareOptions = useCallback(async () => {
    let dates = [];

    intervals?.forEach((i) => {
      dates.push(i.start);
      dates.push(i.end);
    });

    let min = Math.min(...dates);
    let max = Math.max(...dates);

    let options = getDatesBetween(new Date(min), new Date(max));

    setOptions(options);
  }, [intervals]);

  useEffect(() => {
    prepareOptions();
  }, [prepareOptions]);

  const setInitialDate = useCallback(async () => {
    if (selectedDate === null && options.length > 0) {
      updateSelectedDate(options[0]);
    }
  }, [options, selectedDate, updateSelectedDate]);

  useEffect(() => {
    setInitialDate();
  }, [setInitialDate]);

  return (
    options?.length > 1 && (
      <NavigationWrapper className={className}>
        <Button onClick={() => onNavClick(-1)} disabled={!hasPrevious()}>
          <PrevIcon></PrevIcon>
        </Button>

        <SelectedDate>
          {selectedDate ? dayjs(selectedDate).format("D.MM.YY") : ""}
        </SelectedDate>

        <Button onClick={() => onNavClick(1)} disabled={!hasNext()}>
          <NextIcon></NextIcon>
        </Button>
      </NavigationWrapper>
    )
  );
};

export default DateNavigation;
