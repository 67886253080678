import React from "react";
import ContactlessProof from "./ContactlessProof";
import RegularProof from "./RegularProof";

const DeliveryProof = ({ deliveryProof }) => {
  if (!deliveryProof) {
    return null;
  }

  return (
    <>
      {deliveryProof.proofType === "contactless" ? (
        <ContactlessProof
          deliveryProof={deliveryProof.contactlessProof}
        ></ContactlessProof>
      ) : (
        <RegularProof
          deliveryProof={deliveryProof.inPersonProof}
        ></RegularProof>
      )}
    </>
  );
};

export default DeliveryProof;
