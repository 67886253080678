import React, { useState } from "react";
import * as dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import Card from "../card/Card";
import Counter from "../counter/Counter";
import StopPhotos from "./StopPhotos";
import {
  Button,
  dateFormat,
  FieldWrapper,
  TextButton,
} from "../CommonStyledComponents";
import StopType from "../stopType/StopType";
import DoNotDeliver from "../doNotDeliver/DoNotDeliver";
import styled from "styled-components";
import time from "../../images/time-icon.svg";

const TimeWrapper = styled.div`
  position: relative;
  padding: 0px 0px 0px 27px;
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  letter-spacing: -0.3px;

  .time {
    color: ${(props) => props.theme.colors.scienceBlue};
  }

  .order-on-hold {
    color: #f95b29;
  }
`;

const TimeIcon = styled.span`
  display: inline-block;
  width: 22px;
  height: 22px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 22px 22px;
  position: absolute;
  left: 0px;
  top: calc(50% - 11px);
  background-image: url("${time}");
  left: 0px;
`;

const CompletedStop = styled.span`
  color: #00cea9;
`;

const VisitAttempted = styled.span`
  color: #F95B29;
`;

const YourStop = ({
  yourStop,
  isEditable = true,
  additionalInfo,
  images,
  onAdditionalInfoChange,
  onSave,
  onImageSave,
  status,
  updateStatus,
  isOrderManualOnHold,
  allowDoNotDeliver,
}) => {
  const [nameOnDoorBell, setNameOnDoorBell] = useState(additionalInfo.doorbell);

  const { t } = useTranslation();

  let buildAddress = () => {
    let {
      address1,
      address2,
      address3,
      addressNumber,
      postalCode,
      addressRegion,
    } = yourStop.address;

    let completeAddress = "";

    if (address1 != null) {
      completeAddress += address1;
    }

    if (address2 != null) {
      completeAddress = completeAddress + " " + address2;
    }

    if (address3 != null) {
      completeAddress = completeAddress + " " + address3;
    }

    if (addressNumber != null) {
      completeAddress = completeAddress + " " + addressNumber;
    }

    if (postalCode != null) {
      completeAddress = completeAddress + ", " + postalCode;
    }

    if (addressRegion != null) {
      completeAddress = completeAddress + ", " + addressRegion;
    }

    return completeAddress;
  };

  return (
    <Card>
      <div className="type-time-wrapper">
        <StopType isPickup={yourStop.type === "pickup"} />
        <StopStatus
          stop={yourStop}
          status={status}
          isOrderManualOnHold={isOrderManualOnHold}
        />
      </div>

      <h3>{yourStop.name}</h3>
      <p>{buildAddress()}</p>

      {isEditable && (
        <>
          <form>
            <TextButton
              onClick={(e) => {
                e.preventDefault();
                setNameOnDoorBell(!nameOnDoorBell);
                if (!nameOnDoorBell) {
                  onAdditionalInfoChange({
                    ...additionalInfo,
                    doorbell: "",
                  });
                }
              }}
            >
              {nameOnDoorBell
                ? t("same_name_door_bell")
                : t("other_name_door_bell")}
            </TextButton>
            {nameOnDoorBell ? (
              <FieldWrapper>
                <label htmlFor="name-on-door-bell">
                  {t("name_on_door_bell")}
                </label>
                <input
                  type="text"
                  name="name-on-door-bell"
                  id="name-on-door-bell"
                  placeholder={t("eg_eva_johnsen")}
                  value={additionalInfo.doorbell || ""}
                  onChange={(doorbell) =>
                    onAdditionalInfoChange({
                      ...additionalInfo,
                      doorbell: doorbell.target.value,
                    })
                  }
                />
              </FieldWrapper>
            ) : null}
            <Counter
              label={t("floor")}
              placeholder={t("eg_1")}
              value={additionalInfo.floor || 0}
              onChange={(floor) =>
                onAdditionalInfoChange({
                  ...additionalInfo,
                  floor: floor,
                })
              }
            />
            <FieldWrapper>
              <label htmlFor="delivery-note">{t("note_to_driver")}</label>
              <textarea
                name="delivery-note"
                id="delivery-note"
                cols="30"
                rows="3"
                placeholder={t("write_your_note")}
                value={additionalInfo.instructions || ""}
                onChange={(instructions) =>
                  onAdditionalInfoChange({
                    ...additionalInfo,
                    instructions: instructions.target.value,
                  })
                }
              ></textarea>
            </FieldWrapper>

            <Button
              disabled={updateStatus?.loading ?? false}
              type="submit"
              onClick={(e) => {
                e.preventDefault();
                onSave();
              }}
            >
              {t("update_information")}
            </Button>
          </form>
          {updateStatus?.hasExecuted ? (
            <div>
              {updateStatus.loading
                ? t("update_info_updating")
                : updateStatus.error
                ? t("update_info_error")
                : t("update_info_success")}
            </div>
          ) : null}
          <StopPhotos
            images={images}
            saveImage={(newImage) => onImageSave(newImage)}
          />
          <DoNotDeliver
            triggerType={"link"}
            triggerText={t("is_something_wrong")}
            modalTitle={t("describe_the_problem")}
            warningText={t("is_something_wrong_warning")}
            reason={"WRONG_DELIVERY_INFO_REASON"}
            disabled={!allowDoNotDeliver}
          ></DoNotDeliver>
        </>
      )}
    </Card>
  );
};

const getStopTime = (stop, status) => {
  // Statuses are:
  // received
  // assigned
  // collected
  // delivered

  // When the order location is "dispatched" or "arrived" we should show the Estimated start time.
  // When the order location is "planned" we should show the TW as the ETA

  if (status.assigned || status.collected || status.delivered) {
    let start = dayjs(stop.estimatedStart);

    if (!start.isValid()) {
      return null;
    }

    return timeSpan(start);
  }

  let start = dayjs(stop.timeWindow.earliest);
  let end = dayjs(stop.timeWindow.latest);

  if (!start.isValid() || !end.isValid()) {
    return null;
  }

  return `${start.format(dateFormat)} - ${end.format("HH:mm")}`;
};

const StopStatus = ({ stop, status, isOrderManualOnHold }) => {
  const { t } = useTranslation();

  if (stop.type === "delivery") {
    if (status.DisplayOrderStatus === "delivered" || status.DisplayOrderStatus === "complete") {
      return (
        <CompletedStop>
          {t("delivered")}: {dayjs(status.delivered).format(dateFormat)}
        </CompletedStop>
      );
    } else if (status.DisplayOrderStatus === "returned_to_origin" || status.DisplayOrderStatus === "pending_delivery_rescheduling" || status.DisplayOrderStatus === "delivery_rescheduled") {
      return (
        <VisitAttempted>
          {t("delivery_attempted")}: {dayjs(status.lastDeliveryAttempt).format(dateFormat)}
        </VisitAttempted>
      );
    } else {
      return (
        <TimeWrapper>
          

          {isOrderManualOnHold && (
            <span className="order-on-hold">{t("on_hold")}</span>
          )}

          {!isOrderManualOnHold && (
            <>
              {getStopTime(stop, status) !== null && (
                
                <span>
                  <TimeIcon></TimeIcon>
                  {`${t("estimated")} ${t("delivery").toLowerCase()}: `}
                  <span className="time">{getStopTime(stop, status)}</span>
                </span>
              )}

              {getStopTime(stop, status) === null && (
                <span>{`${t(status.DisplayOrderStatus)}`}</span>
              )}
            </>
          )}
        </TimeWrapper>
      );
    }
  } else {
    if (status.DisplayOrderStatus === "in_transit" || status.DisplayOrderStatus === "delivered" || status.DisplayOrderStatus === "complete") {
      return (
        <CompletedStop>
          {t("picked_up")}: {dayjs(status.collected).format(dateFormat)}
        </CompletedStop>
      );
    } else if (status.DisplayOrderStatus === "pickup_rescheduled" || status.DisplayOrderStatus === "pending_pickup_rescheduling") {
      return (
        <VisitAttempted>
          {t("pickup_attempted")}: {dayjs(status.lastPickupAttempt).format(dateFormat)}
        </VisitAttempted>
      );
    } else {
      return (
        <TimeWrapper>
          

          {isOrderManualOnHold && (
            <span className="order-on-hold">{t("on_hold")}</span>
          )}

          {!isOrderManualOnHold && (
            <>
              {getStopTime(stop, status) !== null && (
                <span>
                  <TimeIcon></TimeIcon>
                  {`${t("estimated")} ${t("pick_up").toLowerCase()}: `}
                  <span className="time">{getStopTime(stop, status)}</span>
                </span>
              )}

              {getStopTime(stop, status) === null && (
                <span>{`${t(status.DisplayOrderStatus)}`}</span>
              )}
            </>
          )}
        </TimeWrapper>
      );
    }
  }
};

const timeSpan = (date, spanLengthInMinutes) => {
  if (spanLengthInMinutes === undefined) {
    spanLengthInMinutes = 40;
  }

  let halfSpan = spanLengthInMinutes / 2;

  let from = dayjs(date).subtract(halfSpan, "minutes");
  let to = dayjs(date).add(halfSpan, "minutes");

  if (spanLengthInMinutes === 0) {
    return `${from.format(dateFormat)}`;
  }

  return `${from.format(dateFormat)} - ${to.format("HH:mm")}`;
};

export default YourStop;
